import axios from "axios";
const apiPath = "patron"
export default {
    namespaced: true,
    state: {
        patron: []
    },
    mutations: {
        SET_PATRON(state, _patron) {
            state.patron = _patron.sort((p1, p2) => p1.ordre-p2.ordre);
        },
        ADD_PATRON(state, _patron) {
            const indexPatron = state.patron.findIndex((patron) => patron.id === _patron.id)
            console.log(_patron, indexPatron)
            if(indexPatron < 0) {
                state.patron.push(_patron)
            }
        },
        PUT_PATRON(state, _patron) {
            const indexToUpdate = state.patron.findIndex((patron) => patron.id === _patron.id)
            if (indexToUpdate >= 0) {
                state.patron.splice(indexToUpdate, 1, _patron)
            }
        },
        DELETE_PATRON(state, _patron) {
            const indexToUpdate = state.patron.findIndex((patron) => patron.id === _patron.id)
            if (indexToUpdate >= 0) {
                state.patron.splice(indexToUpdate, 1)
            }
        }
    },
    actions: {
        fetchPatrons({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_URL_BACK}/`+ apiPath)
                .then((response) => {
                    commit('SET_PATRON', response.data)
                })
        },
        creerPatron({commit}, data) {
            return axios
              .post(`${process.env.VUE_APP_URL_BACK}/`+ apiPath, data)
              .then((response) => {
                  commit('ADD_PATRON', response.data)
              })
        },
        updatePatron({commit}, data) {
            return axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${data.patron.id}`, data.patron)
              .then((response) => {
                  commit('PUT_PATRON', response.data)
              })
        },
        orderUpPatron({commit}, patronId) {
            return axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${patronId}/orderUp`)
              .then((response) => {
                  commit('SET_PATRON', response.data)
              })
        },
        orderDownPatron({commit}, patronId) {
            return axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${patronId}/orderDown`)
              .then((response) => {
                  commit('SET_PATRON', response.data)
              })
        },
        deletePatron({commit}, patron) {
            return axios
                .delete(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${patron.id}`)
                .then(commit('DELETE_PATRON', patron))
        },
    },
    getters: {
        getPatrons(state) {
            return state.patron;
        },
        getPatronById: (state) => (_patronId) => {
            return state.patron.find((patron) => patron.id === _patronId)
        },

    },
}