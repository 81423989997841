<template>
  <v-app-bar color="black" app>
    <v-app-bar-nav-icon
        color="primary"
        @click="drawer = !drawer">
    </v-app-bar-nav-icon>
    <v-app-bar-title id="toolBarTitle">Planning des rencontres </v-app-bar-title>
    <v-img src="@/assets/logoCoquelicot.png"></v-img>
    <v-spacer></v-spacer>
    <template v-slot:extension  v-if="!drawer" >
      <v-container class="d-none d-md-flex">
        <router-link class="pa-2" to="/"><v-btn variant="elevated" color="primary">Accueil</v-btn></router-link>
        <router-link class="pa-2" to="/plannings"><v-btn variant="elevated" color="primary">Plannings</v-btn></router-link>
        <router-link class="pa-2" to="/equipes"><v-btn variant="elevated" color="primary">Equipes</v-btn></router-link>
        <router-link v-if="isConnected && user.isPatronEnabled" class="pa-2" to="/patrons"><v-btn border variant="elevated" color="primary" width="100%">Patrons</v-btn></router-link>
        <v-spacer></v-spacer>
        <boutton-connexion v-if="!isConnected"></boutton-connexion>
        <v-btn v-else @click="disconnectUser" color="red" variant="elevated" >Se deconnecter</v-btn>
      </v-container>
    </template>
  </v-app-bar>
  <!-- Add a navigation bar -->
  <v-navigation-drawer
      color="black"
      v-model="drawer"
      temporary
  >
    <v-list>
      <v-list-item>
        <router-link to="/"><v-btn text="" border width="100%" color="primary">Accueil</v-btn></router-link>
      </v-list-item>
      <v-list-item>
        <router-link to="/plannings"><v-btn text="" border width="100%" color="primary">Plannings</v-btn></router-link>
      </v-list-item>
      <v-list-item>
        <router-link to="/equipes"><v-btn text="" border width="100%" color="primary">Equipes</v-btn></router-link>
      </v-list-item>
      <v-list-item v-if="isConnected && user.isPatronEnabled" >
        <router-link to="/patrons"><v-btn text="" border width="100%" color="primary">Patrons</v-btn></router-link>
      </v-list-item>
      <v-divider></v-divider>
      <v-list-item>
        <boutton-connexion width="100%" v-if="!isConnected"></boutton-connexion>
        <v-btn v-else @click="disconnectUser" width="100%" color="red" text="" border>Se deconnecter</v-btn>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>

import {mapActions, mapGetters} from "vuex";
import BouttonConnexion from "@/components/connexion/boutton-connexion.vue";

export default {
  name: "barre-navigation",
  components: {BouttonConnexion},
  data () {
    return {
      drawer: false,
      tab: null
    }
  },
  computed: {
    ...mapGetters ({
      isConnected: "user/isConnected",
      user: "user/getUser"
    })
  },
  methods: {
    ...mapActions({
      disconnectUser: "user/disconnectUser"
    })
  },
};
</script>

<style lang="scss" scoped>
#toolBarTitle {
  color: rgb(var(--v-theme-primary));
}
</style>