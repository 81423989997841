<template>
  <v-app>
    <v-main>
      <barre-navigation></barre-navigation>
      <router-view class="pa-0"></router-view>
    </v-main>
  </v-app>
</template>

<script>

import BarreNavigation from "@/components/_common/barre-navigation.vue";

export default {
  name: 'App',
  components: {BarreNavigation},
}
</script>

<style lang="scss" scoped>
.v-main {
  background-color: rgb(var(--v-theme-primary));
}
.v-container {

  padding: 1vh !important;
  max-width: 98vw !important;
}
</style>
