<template>
  <v-btn
      icon
      color="red"
      variant="elevated"
  >
    <v-icon>mdi-delete</v-icon>
    <v-dialog
        v-model="dialog"
        activator="parent"
        width="auto"
    >
      <v-card>
        <v-card-title>
          Confirmez-vous cette supression ?
        </v-card-title>
        <common-actions
            action-color="red"
            @VALIDATE="dialog=false;$emit('VALIDATE')"
            @INVALIDATE="dialog=false;"
        >Supprimer</common-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>

import CommonActions from "@/components/_common/common-actions.vue";

export default  {
  name: "delete-dialog",
  components: {CommonActions},
  data() {
    return {
      dialog: false
    }
  },
}
</script>

<style lang="scss" scoped>

</style>>