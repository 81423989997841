<template>
  <tr>
    <td>
      <p>{{ patron.equipe }}</p>
      <p>{{ patron.coach }}</p>
    </td>
    <td>{{ patron.horaire }}</td>
    <td>{{ patron.jourSemaine }}</td>
    <td>
      <v-switch
          color="option"
          :model-value="patron.sontDesignesMarqueurs"
          @click="switchOTMProcedure(patron)"
          :label="patron.sontDesignesMarqueurs ? 'Désignés' : 'Non désignés'"
      ></v-switch>
    </td>
    <td>
      <v-switch
          color="option"
          :model-value="patron.sontDesignesArbitres"
          @click="switchArbitreProcedure(patron)"
          :label="patron.sontDesignesArbitres ? 'Désignés' : 'Non désignés'"
      ></v-switch>
    </td>
    <td>
      <v-row>
        <v-col>
          <v-btn
              :disabled="patron.ordre < 1"
              class="ma-2"
              color="option"
              variant="outlined"
              icon
              @click="orderUpPatron(patron.id)"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
              :disabled="patron.ordre >= nbPatron-1"
              class="ma-2"
              color="option"
              variant="outlined"
              icon
              @click="orderDownPatron(patron.id)"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
          <v-btn
              class="ma-2"
              color="success"
              icon
          >
            <v-icon>mdi-pencil</v-icon>
            <v-dialog
                v-model="dialogEdit"
                activator="parent"
            >
              <edit-patron-card
                  :patron="patron"
                  @INVALIDATE="dialogEdit = false"
                  @UPDATE="p => updateProcedure(p)"
              ></edit-patron-card>
            </v-dialog>
          </v-btn>
          <delete-dialog
              @VALIDATE="dialogDeldete = false; deletePatron(patron)"
          ></delete-dialog>
        </v-col>
      </v-row>
    </td>
  </tr>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DeleteDialog from "@/components/_common/dialogs/delete-dialog.vue";
import EditPatronCard from "@/components/ressources/patron/edit-patron-card.vue";

export default {
  components: {EditPatronCard, DeleteDialog},
  props: {
    patron: {
      type: Object,
      required: true
    },
    nbPatron: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialogEdit: false,
      dialogDeldete: false,
    }
  },
  computed: {
    ...mapGetters ({
      user: "user/getUser"
    }),
  },
  methods: {
    ...mapActions({
      deletePatron: "patron/deletePatron",
      updatePatron: "patron/updatePatron",
      orderUpPatron: "patron/orderUpPatron",
      orderDownPatron: "patron/orderDownPatron",
    }),
    updateProcedure(p) {
      this.updatePatron({patron:p, token:this.user.accessToken});
      this.dialogEdit = false;
    },
    switchArbitreProcedure(p) {
      p.sontDesignesArbitres = !p.sontDesignesArbitres
      this.updatePatron({patron:p, token:this.user.accessToken});
    },
    switchOTMProcedure(p) {
      p.sontDesignesMarqueurs = !p.sontDesignesMarqueurs
      this.updatePatron({patron:p, token:this.user.accessToken});
    }
  },
}
</script>

<style >
@media only screen and (min-width: 1256px) {
  .v-dialog {
    width: 65%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1255px) {
  .v-dialog {
    width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .v-dialog {
    width: 100%;
  }
}
</style>
