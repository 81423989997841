<template>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn
        :color="actionColor"
        variant="flat"
        @click="$emit('VALIDATE')"
    ><slot></slot></v-btn>
    <v-btn
        color="grey"
        variant="flat"
        @click="$emit('INVALIDATE')"
    >Annuler</v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name: "common-actions",
  props: {
    actionColor: {
      type: String,
      default: "success"
    },
  },
}
</script>

<style lang="scss" scoped>

</style>>