<template>
  <v-card class="mt-3" >
    <v-card-title>{{ patron.equipe }}</v-card-title>
    <v-card-subtitle>{{ patron.coach }}</v-card-subtitle>
    <v-card-subtitle>{{ patron.jourSemaine }}, {{ patron.horaire }}</v-card-subtitle>
    <v-card-text>
        <v-row class="ma-0">
          <v-switch
              color="option"
              :model-value="patron.sontDesignesArbitres"
              @click="switchArbitreProcedure(patron)"
              :label="patron.sontDesignesArbitres ? 'Désignés' : 'Non désignés'"
          >
            <template #prepend>
              <v-chip color="officiel">Arbitres</v-chip>
            </template>
          </v-switch>
        </v-row>
        <v-row class="ma-0">
          <v-switch
              color="option"
              :model-value="patron.sontDesignesMarqueurs"
              @click="switchOTMProcedure(patron)"
              :label="patron.sontDesignesMarqueurs ? 'Désignés' : 'Non désignés'"
          >
            <template #prepend>
              <v-chip color="officiel">Marqueurs</v-chip>
            </template>
          </v-switch>
        </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row justify="space-evenly">
          <v-btn
              :disabled="patron.ordre < 1"
              class="ma-2"
              color="option"
              variant="outlined"
              icon
              @click="orderUpPatron(patron.id)"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
              :disabled="patron.ordre >= nbPatron-1"
              class="ma-2"
              color="option"
              variant="outlined"
              icon
              @click="orderDownPatron(patron.id)"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
          <v-btn
              class="ma-2"
              color="success"
              icon
          >
            <v-icon>mdi-pencil</v-icon>
            <v-dialog
                v-model="dialogEdit"
                activator="parent"
            >
              <edit-patron-card
                  :patron="patron"
                  @INVALIDATE="dialogEdit = false"
                  @UPDATE="p => updateProcedure(p)"
              ></edit-patron-card>
            </v-dialog>
          </v-btn>
          <delete-dialog
              @VALIDATE="dialogDeldete = false; deletePatron(patron)"
          ></delete-dialog>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DeleteDialog from "@/components/_common/dialogs/delete-dialog.vue";
import EditPatronCard from "@/components/ressources/patron/edit-patron-card.vue";

export default {
  components: {EditPatronCard, DeleteDialog},
  props: {
    patron: {
      type: Object,
      required: true
    },
    nbPatron: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      dialogEdit: false,
      dialogDeldete: false,
    }
  },
  computed: {
    ...mapGetters ({
      user: "user/getUser"
    }),
  },
  methods: {
    ...mapActions({
      deletePatron: "patron/deletePatron",
      updatePatron: "patron/updatePatron",
      orderUpPatron: "patron/orderUpPatron",
      orderDownPatron: "patron/orderDownPatron",
    }),
    updateProcedure(p) {
      this.updatePatron({patron:p, token:this.user.accessToken});
      this.dialogEdit = false;
    },
    switchArbitreProcedure(p) {
      p.sontDesignesArbitres = !p.sontDesignesArbitres
      this.updatePatron({patron:p, token:this.user.accessToken});
    },
    switchOTMProcedure(p) {
      p.sontDesignesMarqueurs = !p.sontDesignesMarqueurs
      this.updatePatron({patron:p, token:this.user.accessToken});
    }
  },
}
</script>

<style >
@media only screen and (min-width: 1256px) {
  .v-dialog {
    width: 65%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1255px) {
  .v-dialog {
    width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .v-dialog {
    width: 100%;
  }
}
</style>
