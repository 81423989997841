<template>
  <v-container class="ma-4" v-if="equipes !== undefined">
    <dialog-creation-equipe class="mb-4" variant="elevated"></dialog-creation-equipe>
    <div v-if="equipes.length > 0">
      <v-row>
        <v-col
          cols="3" sm="1" md="2" lg="3"
          v-for="equipe in equipes" :key="equipe.id"
        >
          <carte-equipe :equipe="equipe"></carte-equipe>
        </v-col>
      </v-row>
    </div>
    <no-content-card v-else :enable-actions="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')">
      Oups ^^ : il n'y a aucune equipes !
      <template #actions>
        <dialog-creation-equipe></dialog-creation-equipe>
      </template>
    </no-content-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import NoContentCard from "@/components/_common/noContentCard.vue";
import DialogCreationEquipe from "@/components/ressources/equipe/dialog-creation-equipe.vue";
import CarteEquipe from "@/components/ressources/equipe/representations/carte-equipe.vue";

export default {
  name: "equipes-view",
  components: {CarteEquipe, DialogCreationEquipe, NoContentCard},
  props: {
    equipeId: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      equipes: "equipe/getEquipes",
    }),
  },
  methods: {
    ...mapActions({
      fetchEquipes: "equipe/fetchEquipes"
    })
  },
  created() {
    this.fetchEquipes()
  }
}
</script>

<style scoped lang="scss">

</style>