import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import KComponents from 'k-component-lib'
import 'k-component-lib/dist/style.css'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    themes: {
      options: { customProperties: true },
      light: {
        dark: false,
        variables: {
          'border-opacity': 0.25,
        }, // ✅ this property is required to avoid Vuetify crash

        colors: {
          // Workaround: Custom colors seem to erase default colors, so we need to include the default colors (of `light` or `dark` theme)
          background: '#ffffff',
          surface: '#ffffff',
          'surface-1': '#11111b',
          primary: 'rgb(252,69,60)',
          option: '#7287fd',
          domicile: '#7287fd',
          exterieur: '#fe640b',
          officiel: '#40a02b',
          designes: '#179299',
          success: '#40a02b',
          error: '#d20f39',
        },
      },
    }
  }
})

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(KComponents)
  .mount('#app')
