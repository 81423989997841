<template>
  <dialog-creation>
    Générer un planning
    <template v-slot:form="{ onClose }">
      <v-row>
        <v-col cols="12" sm="12" md="6">
          <v-form
              ref="formDate"
              @submit.prevent="() => {
                submit({semaine:bySemaine.semaine, anne:bySemaine.anne})
                onClose
              }"
          >
            <v-label>
              Par n° de semaine
            </v-label>
            <v-text-field
                @input="v$.bySemaine.semaine.$touch()"
                @blur="v$.bySemaine.semaine.$touch()"
                type="number"
                v-model.trim="bySemaine.semaine"
                label="Semaine"
                :error-messages="semaineErrors"
                required
            ></v-text-field>
            <v-text-field
                @input="v$.bySemaine.anne.$touch()"
                @blur="v$.bySemaine.anne.$touch()"
                type="number"
                v-model.trim="bySemaine.anne"
                :error-messages="anneErrors"
                label="Année"
                required
            ></v-text-field>
            <v-btn type="submit" color="success" variant="flat" @click="dialog = false">Créer !</v-btn>
          </v-form>
        </v-col>
        <v-col cols="12" sm="12" md="6">

          <v-form
              ref="formSemaine"
              @submit.prevent="() => {
                submitDate(byDate.date)
                onClose
              }"
          >
            <v-label>
              Par date
            </v-label>
            <v-text-field
                @input="v$.byDate.date.$touch()"
                @blur="v$.byDate.date.$touch()"
                type="date"
                v-model="byDate.date"
                :error-messages="dateErrors"
                label="Anne"
                required
            ></v-text-field>
            <v-btn type="submit" color="success" variant="flat">Créer !</v-btn>
          </v-form>
        </v-col>
      </v-row>
    </template>
    <template #actions>
      <v-btn color="grey" variant="flat" @click="reset()">Abandonner</v-btn>
    </template>
  </dialog-creation>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import {between, helpers, minValue, required} from "@vuelidate/validators";
import {mapActions} from "vuex";
import moment from "moment"
import DialogCreation from "@/components/_common/dialogs/dialog-creation.vue";

moment.updateLocale('es', {
  week: {
    dow : 1, // Monday is the first day of the week.
  }
});
export default {
  name: "dialog-creation-planning",
  components: {DialogCreation},
  props: {
    width: {
      type: String,
      default: ""
    },
    semaineBis: {
      type: String,
    },
    anneBis: {
      type: String,
    },
  },
  data () {
    return {
      dialog: false,
      bySemaine: {
        semaine: "",
        anne: "",
      },
      byDate: {
        date: "",
      }
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      bySemaine: {
        semaine: {
          required,
          between: between(1, 53)
        },
        anne: {
          required,
          min: minValue(2023)
        },
      },
      byDate: {
        date: {
          required,
          min: helpers.withMessage("ui", (value) => moment(value).diff(moment.now(), "day") >= 0)
        },
      }
    }
  },
  methods: {
    ...mapActions({
      genererPlanning: "planning/genererPlanning"
    }),
    submit({semaine, anne}) {
      this.v$.bySemaine.$touch()
      if (!this.v$.bySemaine.$invalid) {
        this.genererPlanning({semaine, anne})
        this.reset()
      }
    },
    submitDate(date) {
      this.v$.byDate.$touch()
      if (!this.v$.byDate.$invalid) {
        this.genererPlanning({semaine: moment(date).week(), anne:moment(date).year() })
        this.reset()
      }
    },
    reset() {
      this.$refs.formDate.reset()
      this.$refs.formSemaine.reset()
      this.dialog = false
    }
  },

  computed: {
    semaineErrors() {
      const errors = []
      if (!this.v$.bySemaine.semaine.$dirty) return errors
      this.v$.bySemaine.semaine.between.$invalid && errors.push('La semaine doit étre un nombre entre 1 et 53')
      this.v$.bySemaine.semaine.required.$invalid && errors.push('Le numéro de la semaine requis.')
      return errors
    },
    anneErrors() {
      const errors = []
      if (!this.v$.bySemaine.anne.$dirty) return errors
      this.v$.bySemaine.anne.min.$invalid && errors.push("L'anne doit étre au minimum 2023")
      this.v$.bySemaine.anne.required.$invalid && errors.push("L'année requis.")
      return errors
    },
    dateErrors() {
      const errors = []
      if (!this.v$.byDate.date.$dirty) return errors
      this.v$.byDate.date.min.$invalid && errors.push("La date doit étre ce jour ou dans le futur")
      this.v$.byDate.date.required.$invalid && errors.push("La dfate requis.")
      return errors
    },
  },
  created() {
    this.anne = parseInt(this.anneBis)
    this.semaine = parseInt(this.semaineBis)
  }
}
</script>

<style >
@media only screen and (min-width: 1256px) {
  .v-dialog {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1255px) {
  .v-dialog {
    width: 60%;
  }
}
@media only screen and (max-width: 767px) {
  .v-dialog {
    width: 100%;
  }
}
</style>