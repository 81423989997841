<template>
  <v-container v-if="planningActuel !== undefined || planningsPasse !== undefined || planningsAVenir !== undefined">
    <v-card v-if="planningActuel !== undefined || planningsPasse.length > 0 || planningsAVenir.length > 0">
      <v-card-actions v-if="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')">
        <dialog-creation-planning></dialog-creation-planning>
      </v-card-actions>
      <v-container>
        <v-row class="py-4">
          <v-label>Cette semaine</v-label>
          <v-divider></v-divider>
          <v-col>
            <carte-planning v-if="planningActuel" :planning="planningActuel"></carte-planning>
            <v-label v-else>Aucun planning cette semaine</v-label>
          </v-col>
        </v-row>
        <v-row v-if="planningsAVenir.length > 0" class="py-4">
          <v-label>A venir</v-label>
          <v-divider></v-divider>
          <v-col v-for="planning in planningsAVenir" :key="planning.id">
            <carte-planning :planning="planning"></carte-planning>
          </v-col>
        </v-row>
        <v-row v-else class="py-4">
          <v-label>A venir</v-label>
          <v-divider></v-divider>
          <v-col>
            <v-label>Aucun planning cette semaine</v-label>
          </v-col>
        </v-row>
        <v-row v-if="planningsPasse.length > 0" class="py-4">
          <v-label>Précédantes semaines</v-label>
          <v-divider></v-divider>
          <v-col  v-for="planning in planningsPasse" :key="planning.id">
            <carte-planning :planning="planning"></carte-planning>
          </v-col>
        </v-row>
        <v-row v-else class="py-4">
          <v-label>Précédantes semaines</v-label>
          <v-divider></v-divider>
          <v-col>
            <v-label>Aucun planning cette semaine</v-label>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <no-content-card v-else :enable-actions="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')">
      Oups ^^ : il n'y a aucun planning !
      <template #actions>
        <dialog-creation-planning></dialog-creation-planning>
      </template>
    </no-content-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DialogCreationPlanning from "@/components/ressources/planning/dialog-creation-planning.vue";
import CartePlanning from "@/components/ressources/planning/representations/carte-planning.vue";
import NoContentCard from "@/components/_common/noContentCard.vue";

export default {
  name: "historique-planning",
  components: {NoContentCard, CartePlanning, DialogCreationPlanning},
  computed: {
    ...mapGetters({
      planningsPasse: "planning/getPlanningsPasse",
      planningsAVenir: "planning/getPlanningsAVenir",
      planningActuel: "planning/getPlanningsActuel",
      isConnected: "user/isConnected",
      user: "user/getUser"
    }),
  },
  methods: {
    ...mapActions({
      fetchPlannings: "planning/fetchPlannings"
    })
  },
  created() {
    this.fetchPlannings()
  }
}
</script>