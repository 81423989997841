<template>
  <v-card-text>
    <p>Si jamais vous pensez vraiment qu'il deverait y en avoir :</p>
    <p>- Raffaichissez la page (on ne sait jamais)</p>
    <p>- Contactez l'administateur du site, il vous aidera ^^</p>
  </v-card-text>
</template>

<script>

export default {
  name: "no-content-text"
}
</script>

<style lang="scss" scoped>

</style>