import { createRouter, createWebHashHistory } from 'vue-router'
import PlanningView from "@/views/PlanningView.vue";
import HistoriquePlanning from "@/views/HistoriquePlanning.vue";
import PatronsView from "@/views/PatronsView.vue";
import TestView from "@/views/TestView.vue";
import moment from "moment";
import EquipesView from "@/views/equipe/EquipesView.vue";
import EquipeView from "@/views/equipe/EquipeView.vue";
import EquipeAdministration from "@/views/equipe/EquipeAdministration.vue";


const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      return {
        name: 'planning',
        params: {semaine: moment().week(), anne: moment().year()}}
    }
  },
  {
    path: '/planning/:semaine/:anne',
    name: 'planning',
    component: PlanningView,
    props:true
  },
  {
    path: '/plannings',
    name: 'plannings',
    component: HistoriquePlanning
  },
  {
    path: '/patrons',
    name: 'patrons',
    component: PatronsView
  },
  {
    path: '/equipes',
    name: 'equipes',
    component: EquipesView
  },
  {
    path: '/equipe/:equipeId',
    name: 'equipe',
    component: EquipeView,
    props:true,
    children: [
      {
        path: 'administration',
        name: 'administration-equipe',
        component: EquipeAdministration,
        props:true,
      }
    ]
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
