import { createStore } from 'vuex'
import planning from "@/store/module/planning";
import user from "@/store/module/user"
import patron from "@/store/module/patron"
import common from "@/store/module/common";
import equipe from "@/store/module/equipe";
import categorie from "@/store/module/categorie";
import ligue from "@/store/module/ligue";
import genre from "@/store/module/genre";
export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    planning,
    user,
    patron,
    common,
    equipe,
    categorie,
    ligue,
    genre
  }
})
