import axios from "axios";
const apiPath = "genre"
export default {
    namespaced: true,
    state: {
        genre: []
    },
    mutations: {
        SET_GENRE(state, _genre) {
            state.genre = _genre;
        },
    },
    actions: {
        fetchGenre({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_URL_BACK}/`+ apiPath)
                .then((response) => {
                    commit('SET_GENRE', response.data)
                })
        },
    },
    getters: {
        getGenre(state) {
            return state.genre;
        },

    },
}