import axios from "axios";
const apiPath = "categorie"
export default {
    namespaced: true,
    state: {
        categorie: []
    },
    mutations: {
        SET_CATEGORIE(state, _categorie) {
            state.categorie = _categorie;
        },
    },
    actions: {
        fetchCategorie({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_URL_BACK}/`+ apiPath)
                .then((response) => {
                    commit('SET_CATEGORIE', response.data)
                })
        },
    },
    getters: {
        getCategorie(state) {
            return state.categorie;
        },

    },
}