export default {
    namespaced: true,
    state: {
        common: {
            adresse: "Salle Omnisports Georges Pédoussaut, Route de Castagnac 09210 Lézat sur Lèze"
        }
    },
    mutations: {},
    actions: {},
    getters: {
        getCommons(state) {
            return state.common;
        },
    },
}