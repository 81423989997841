<template>
    <v-card
      :color="density === 'compact' ? 'success': 'white'"
      :variant="density === 'compact' ? 'outlined': 'flat'"
      :max-width="density === 'compact' ? 'auto': 200"
    >
      <v-card-title
        v-if="density === 'compact'"
        class="petit-titre"
      >{{ title }}</v-card-title>
      <div
          v-if="items.length > 0"
      >
        <v-chip
            :density="density"
            class="ma-1"
            variant="tonal"
            color="officiel"
            :closable="canEdit"
            v-for="item in items"
            :key="item.id"
            @click:close="$emit('SUPPRIMER_OFFICIEL', item.id)"
        >
          {{item.prenom}} {{ item.nom }}
        </v-chip>
      </div>
      <v-card-subtitle v-else>Aucun officiel</v-card-subtitle>

      <v-card-actions v-if="canEdit">
        <v-dialog
            v-model="dialog"
            activator="parent"
            width="auto"
        >
          <template v-slot:activator="{ props }">
            <v-btn border prepend-icon="mdi-plus" v-bind="props" color="success" size="small">
              S'ajouter
            </v-btn>
          </template>
          <template v-slot:default="{ isActive }">
            <card-form-officiel :raison="raison" @AJOUTER_OFFICIEL="(data) => {isActive.value = false; $emit('AJOUTER_OFFICIEL', data)}" @CANCEL="isActive.value = false"></card-form-officiel>
          </template>
        </v-dialog>
      </v-card-actions>
    </v-card>
</template>

<script>


import CardFormOfficiel from "@/components/ressources/officiel/card-form-officiel.vue";

export default {
  components: {CardFormOfficiel},
  props: {
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    raison: {
      type: String
    },
    canEdit: {
      type: Boolean
    },
    density: {
      type: String
    }
  },
  data() {
    return {
      dialog: false
    }
  },
}
</script>

<style scoped>
.petit-titre {
  font-size: 16px;
}
</style>