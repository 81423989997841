<template>
  <v-container v-if="plannings !== undefined">
    <div class="pa-0" v-if="planning !== undefined">
      <v-card class="mb-2">
        <v-card-title>Semaine {{planning.semaine}} du {{ getDateFriday }} au {{ getDateSunday }}</v-card-title>
        <v-card-subtitle>{{planning.rencontres.length}} rencontres cette semaine</v-card-subtitle>
        <v-card-actions v-if="user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN'">
          <dialog-ajout-rencontre @VALIDATE="(r) => creerRencontre({rencontre:r, _planning:planning})"></dialog-ajout-rencontre>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-card-text
            class="hidden-sm-and-down"
            v-if="planning.rencontres.length > 0"
        >
          <table-rencontre
              :is-admin="user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN'"
              :is-user="user.role !== undefined"
              :planning="planning"
          ></table-rencontre>
        </v-card-text>
      </v-card>

      <div class="hidden-md-and-up" >
        <cartes-rencontre
            :planning="planning"
            :is-admin="user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN'"
            :is-user="user.role !== undefined"
        ></cartes-rencontre>
      </div>
    </div>
    <no-content-card v-else :enable-actions="user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN'">
      Oups ^^ : il n'y a pas de planning pour la semaine {{semaine}} de {{anne}}
      <template #actions>
        <dialog-creation-planning :anne-bis="anne" :semaine-bis="semaine"></dialog-creation-planning>
      </template>
    </no-content-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment"
import CartesRencontre from "@/components/ressources/rencontre/representations/carte-rencontre.vue";
import TableRencontre from "@/components/ressources/rencontre/representations/table-rencontres.vue";
import DialogCreationPlanning from "@/components/ressources/planning/dialog-creation-planning.vue";
import NoContentCard from "@/components/_common/noContentCard.vue";

export default {
  name: "planning-view",
  components: {
    NoContentCard,
    CartesRencontre,
    TableRencontre,
    DialogCreationPlanning,

  },
  props: {
    anne: {
      type: Number,
    },
    semaine: {
      type: Number,
    },
  },
  computed: {
    ...mapGetters({
      plannings: "planning/getPlanningBySemaineAndAnne",
      user: "user/getUser",
    }),
    planning() {
      return this.plannings(this.semaine, this.anne)
    },
    getDateFriday() {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment().day("vendredi").year(this.planning.anne).week(this.planning.semaine).format('l');
    },
    getDateSunday() {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment().day("dimanche").year(this.planning.anne).week(this.planning.semaine).format('l');
    }
  },
  methods: {
    ...mapActions({
      ajouterArbitre: "planning/ajouterArbitreRencontre",
      ajouterMarqueur: "planning/ajouterMarqueurRencontre",
      orderUpRencontre: "planning/orderUpRencontre",
      orderDownRencontre: "planning/orderDownRencontre",
      supprimerArbitre: "planning/supprimerArbitreRencontre",
      supprimerMarqueur: "planning/supprimerMarqueurRencontre",
      creerRencontre:"planning/creerRencontre",
      modifierRencontre: "planning/modifierRencontre",
      supprimerRencontre: "planning/supprimerRencontre",
      fetchPlannings: "planning/fetchPlannings",
    }),
    getDay(date) {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment(date).format('dddd');
    },
  },
  created() {
    this.fetchPlannings()
  }
}
</script>

<style scoped>

</style>