<template>
  <div>

    <div class="s-text">
      <template v-if="editing">
        <v-row align="center" no-gutters>
          <slot name="champ"></slot>
          <v-btn class="ml-2 align-center" color="grey darken-1" text="" @click="cancelUpdate">Annuler</v-btn>
          <v-btn class="ml-2" color="info darken-1" dark @click="validateUpdate">{{ titreAction }}</v-btn>
        </v-row>
      </template>
      <template v-if="!editing">
        <v-row no-gutters class="s-text-read">
          <div class="text-overline grey--text text--darken-1 d-flex">
            {{ titre }}
            <v-tooltip right v-if="tooltip !== ''" class="" max-width="50vh">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" class="ml-1" small>mdi-information</v-icon>
              </template>
              <span>{{ tooltip }}</span>
            </v-tooltip>
          </div>
          <div :class="textClass">
            {{ contentToDisplay }}
          </div>
        </v-row>
        <v-btn v-if="editable" :loading="loading" class="s-text-action ml-2" outlined color="info" @click="startUpdate">{{ titreAction }}</v-btn>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'k-info-modifiable',
  props: {
    titre: {
      type: String,
      required: true,
    },
    valeur: {
      type: String,
      required: false,
    },
    titreAction: {
      type: String,
      required: false,
      default: 'Modifier',
    },
    noContentMessage: {
      type: String,
      default: 'Non renseigné',
    },
    tooltip: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
    }
  },
  computed: {
    contentToDisplay() {
      if (!this.valeur || this.valeur === '') {
        return this.noContentMessage
      } else {
        return this.valeur
      }
    },
    textClass() {
      if (!this.valeur || this.valeur === '') {
        return 'text-body-2 error--text'
      } else {
        return 'text-body-2 black--text'
      }
    },
  },
  methods: {
    startUpdate() {
      this.editing = true
    },
    validateUpdate() {
      this.$emit('validateUpdate')
      this.editing = false
    },
    cancelUpdate() {
      this.$emit('cancelUpdate')
      this.editing = false
    },
  },
}
</script>

<style scoped>
.s-text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 70px;
}

.s-text-read {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.s-text-action {
  display: flex;
}

</style>
