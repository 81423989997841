import axios from "axios";
const apiPath = "auth"

export default {
    namespaced: true,
    state: {
        user: {},
        isConnected: false
    },
    mutations: {
        SET_USER(state, _user) {
            state.user = _user;
            state.isConnected = true
            axios.defaults.headers.common['Authorization'] = `${_user.tokenType} ${_user.accessToken}`
        },
        UNSET_USER(state) {
            state.user = {};
            state.isConnected = false
        }
    },
    actions: {
        tryConnectionUsers({ commit }, data) {
            return axios
                .post(`${process.env.VUE_APP_URL_BACK}/` +apiPath + "/login", data)
                .then((response) => {
                    if (response.status === 200) {
                        commit('SET_USER', response.data)
                    }
                })
        },
        disconnectUser({ commit }) {
            commit('UNSET_USER')
        },
    },
    getters: {
        getUser(state) {
            return state.user;
        },
        isConnected(state) {
            return state.isConnected
        }

    },
}