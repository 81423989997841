import axios from "axios";
import moment from "moment";
const apiPath = "planning"
export default {
    namespaced: true,
    state: {
        planning: []
    },
    mutations: {
        SET_PLANNING(state, _planning) {
            state.planning = _planning;
        },
        ADD_PLANNING(state, _planning) {
            const indexPlanningToUpdate = state.planning.findIndex((planning) => planning.id === _planning.id)
            console.log(_planning, indexPlanningToUpdate)
            if(indexPlanningToUpdate < 0) {
                state.planning.push(_planning)
            }
        },
        PUT_PLANNING(state, _planning) {
            const indexToUpdate = state.planning.findIndex((planning) => planning.id === _planning.id)
            if (indexToUpdate >= 0) {
                state.planning.splice(indexToUpdate, 1, _planning)
            }
        },
        ADD_RENCONTRE(state, {_planning, _rencontre}) {
            const toUpdate = state.planning.find((planning) => planning.id === _planning.id)
            const indexPlanningToUpdate = state.planning.findIndex((planning) => planning.id === _planning.id)
            toUpdate.rencontres.push(_rencontre)
            state.planning.splice(indexPlanningToUpdate, 1, toUpdate)
        },
        PUT_RENCONTRE(state, {_planning, _rencontre}) {
            const toUpdate = state.planning.find((planning) => planning.id === _planning.id)
            const indexPlanningToUpdate = state.planning.findIndex((planning) => planning.id === _planning.id)
            const indexRencontreToUpdate = toUpdate.rencontres.findIndex((recontre) => recontre.id === _rencontre.id)
            if (indexRencontreToUpdate >= 0) {
                toUpdate.rencontres.splice(indexRencontreToUpdate, 1, _rencontre)
                state.planning.splice(indexPlanningToUpdate, 1, toUpdate)
            }
        },
        DELETE_RENCONTRE_V2(state, { _idRencontre, _idPlanning}) {
            const toUpdate = state.planning.find((planning) => planning.id === _idPlanning)
            const indexPlanningToUpdate = state.planning.findIndex((planning) => planning.id === _idPlanning)
            const indexRencontreToUpdate = toUpdate.rencontres.findIndex((recontre) => recontre.id === _idRencontre)
            if (indexRencontreToUpdate >= 0) {
                toUpdate.rencontres.splice(indexRencontreToUpdate, 1)
                state.planning.splice(indexPlanningToUpdate, 1, toUpdate)
            }
        },
        DELETE_PLANNING(state, _planning) {
            const indexToUpdate = state.planning.findIndex((planning) => planning.id === _planning.id)
            if (indexToUpdate >= 0) {
                state.planning.splice(indexToUpdate, 1)
            }
        },
    },
    actions: {
        fetchPlannings({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_URL_BACK}/planning`)
                .then((response) => {
                    commit('SET_PLANNING', response.data)
                })
        },
        ajouterArbitreRencontre({commit}, {planning, rencontre, data}) {
            axios
              .put(`${process.env.VUE_APP_URL_BACK}/rencontre/` + rencontre.id + '/arbitrer', data)
              .then((r) => {
                  if (r.status !== 200) {
                      return null
                  } else {
                      commit('PUT_RENCONTRE', {_planning:planning, _rencontre:r.data})
                      return r.data
                  }
              })
        },
        ajouterMarqueurRencontre({commit}, {planning, rencontre, data}) {
            axios
              .put(`${process.env.VUE_APP_URL_BACK}/rencontre/` + rencontre.id + '/marquer', data)
              .then((r) => {
                  if (r.status !== 200) {
                      return null
                  } else {
                      commit('PUT_RENCONTRE', {_planning:planning, _rencontre:r.data})
                      return r.data
                  }
              })
        },
        supprimerArbitreRencontre({commit}, {planning, rencontre, id}) {
            axios
              .put(`${process.env.VUE_APP_URL_BACK}/rencontre/` + rencontre.id + '/enleverArbitre/'+id)
              .then((r) => {
                  if (r.status !== 200) {
                      return null
                  } else {
                      commit('PUT_RENCONTRE', {_planning:planning, _rencontre:r})
                      return r.data
                  }
              })
        },
        supprimerMarqueurRencontre({commit}, {planning, rencontre, id}) {
            axios
              .put(`${process.env.VUE_APP_URL_BACK}/rencontre/` + rencontre.id + '/enleverMarqueur/'+id)
              .then((r) => {
                  if (r.status !== 200) {
                      return null
                  } else {
                      commit('PUT_RENCONTRE', {_planning:planning, _rencontre:r})
                      return r.data
                  }
              })
        },
        creerRencontre({commit}, {rencontre, _planning}) {
            axios
              .post(`${process.env.VUE_APP_URL_BACK}/planning/`+ _planning.id , rencontre)
              .then((r) => {
                  commit('ADD_RENCONTRE', {_planning, _rencontre:r.data})
              });
        },
        modifierRencontre({commit}, {rencontre, _planning}) {
            console.log(rencontre)
            axios
              .put(`${process.env.VUE_APP_URL_BACK}/rencontre/` + rencontre.id, rencontre)
              .then((r) => {
                commit('PUT_RENCONTRE', {_planning, _rencontre:r.data})
              });
        },
        supprimerRencontre({commit}, {_idRencontre, _idPlanning}) {
            console.log({_idPlanning, _idRencontre})
            axios
              .delete(`${process.env.VUE_APP_URL_BACK}/planning/`+ _idPlanning + '/rencontre/' + _idRencontre)
              .then( () => {
                  commit('DELETE_RENCONTRE_V2', { _idRencontre, _idPlanning})
              });
        },
        genererPlanning({ commit }, data) {
            return axios
              .get(`${process.env.VUE_APP_URL_BACK}/planning/`+data.semaine+ '/'+data.anne)
              .then((response) => {
                  if (response.status === 200) {
                      commit('ADD_PLANNING', response.data)
                  }
              })
        },
        orderUpRencontre({commit}, {planningId, rencontreId}) {
            return axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${planningId}/rencontre/${rencontreId}/orderUp`)
              .then((response) => {
                  commit('PUT_PLANNING', response.data)
              })
        },
        orderDownRencontre({commit}, {planningId, rencontreId}) {
            return axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${planningId}/rencontre/${rencontreId}/orderDown`)
              .then((response) => {
                  commit('PUT_PLANNING', response.data)
              })
        },
        deletePlanning({commit}, planning) {
            return axios
                .delete(`${process.env.VUE_APP_URL_BACK}/` +apiPath + `/${planning.id}`)
                .then(commit('DELETE_PLANNING', planning))
        },
    },
    getters: {
        getPlanningsActuel(state) {

            return state.planning
              .find(
                (planning) =>
                  planning.semaine === moment().week() &&
                  planning.anne === moment().year()
              );
        },
        getPlanningsAVenir(state) {

            return state.planning
              .filter(
                (planning) =>
                  planning.anne > moment().year() ||
                  (planning.anne === moment().year() && planning.semaine > moment().week())
              );
        },
        getPlanningsPasse(state) {

            return state.planning
              .filter(
                (planning) =>
                  planning.anne < moment().year() ||
                  (planning.anne === moment().year() && planning.semaine < moment().week())
              );
        },
        getPlanningBySemaineAndAnne: (state) => (semaine, anne) => {
            return state.planning.find((planning) => ""+planning.semaine === semaine && ""+planning.anne === anne)
        },
    },
}