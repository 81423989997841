<template>
    <v-card class="mt-3" v-for="rencontre in planning.rencontres" :key="rencontre.id">
      <v-card-title>
        <v-row class="pa-2" justify="start">
            <p v-if="rencontre.equipe" class="text-wrap">{{ rencontre.equipe }}</p>
            <v-chip v-else>n/a</v-chip>
            <v-chip class="mx-2" color="primary" >VS</v-chip>
            <p v-if="rencontre.adversaire" class="text-wrap">{{ rencontre.adversaire }}</p>
            <v-chip v-else>n/a</v-chip>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <p>{{ rencontre.dateRencontre == null ? null : getDay(rencontre.dateRencontre) + ", " + (new Date(rencontre.dateRencontre.toLocaleString())).toLocaleDateString()}} {{ rencontre.dateRencontre == null ? null : "à "+ rencontre.horaireRencontre }}</p>
      </v-card-subtitle>
      <v-card-text>
        <v-container>
          <v-row v-if="rencontre.estDomicile">
            <v-chip color="domicile" >A domicile</v-chip>
          </v-row>
          <v-row v-else>
            <v-chip color="exterieur" >A l'extérieur</v-chip>
            <p>{{ rencontre.lieu }}</p>
          </v-row>
        </v-container>
        <v-container
            v-if="rencontre.estDomicile"
        >
          <v-row>
            <v-divider class="ma-2" thickness="2"></v-divider>
          </v-row>
          <v-row>
            <v-col class="pa-1">
              <div v-if="rencontre.sontDesignesArbitres">
                <v-chip
                    class="ma-2 pa-3"
                    color="designes"
                >Arbitres désignés</v-chip>
              </div>
              <chip-container
                  density="compact"
                  title="Arbitres"
                  :canEdit="isUser"
                  raison="arbitre"
                  v-else
                  :items="rencontre.arbitres"
                  @AJOUTER_OFFICIEL="(data) => ajouterArbitre({planning, rencontre, data})"
                  @SUPPRIMER_OFFICIEL="(id) => supprimerArbitre({planning, rencontre, id})"
              ></chip-container>
            </v-col>
            <v-col class="pa-1">
              <div v-if="rencontre.sontDesignesMarqueurs">
                <v-chip
                    class="ma-2 pa-3"
                    color="designes"
                >Marqueurs désignés</v-chip>
              </div>
              <chip-container
                  title="Marqueurs"
                  density="compact"
                  :canEdit="isUser"
                  raison="OTM"
                  v-else
                  :items="rencontre.marqueurs"
                  @AJOUTER_OFFICIEL="(data) => ajouterMarqueur({planning, rencontre, data})"
                  @SUPPRIMER_OFFICIEL="(id) => supprimerMarqueur({planning, rencontre, id})"
              ></chip-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="isAdmin">
        <v-row justify="space-evenly">
          <v-btn
              :disabled="rencontre.ordre < 1"
              class="ma-2"
              color="option"
              variant="outlined"
              icon
              @click="orderUpRencontre({planningId: planning.id, rencontreId:rencontre.id})"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
          <v-btn
              v-if="isAdmin"
              :disabled="rencontre.ordre >= planning.rencontres.length-1"
              class="ma-2"
              color="option"
              variant="outlined"
              icon
              @click="orderDownRencontre({planningId: planning.id, rencontreId:rencontre.id})"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
          <dialog-edition-rencontre
              v-if="isAdmin"
              class="ma-2"
              @VALIDATE="(r) => modifierRencontre({rencontre:r, _planning:planning})"
              :rencontre-bis="rencontre"
          ></dialog-edition-rencontre>
          <delete-dialog
              v-if="isAdmin"
              class="ma-2"
              @VALIDATE="supprimerRencontre({id:rencontre.id, idPlanning:planning.id})"
          ></delete-dialog>
        </v-row>
      </v-card-actions>
    </v-card>
</template>

<script>
import DeleteDialog from "@/components/_common/dialogs/delete-dialog.vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import DialogEditionRencontre from "@/components/ressources/rencontre/dialog-edition-rencontre.vue";
import ChipContainer from "@/components/ressources/officiel/chip-container.vue";

export default {
  components: {ChipContainer, DialogEditionRencontre, DeleteDialog},
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    },
    planning: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      common: "common/getCommons"
    }),
  },
  methods: {
    ...mapActions({
      ajouterArbitre: "planning/ajouterArbitreRencontre",
      ajouterMarqueur: "planning/ajouterMarqueurRencontre",
      orderUpRencontre: "planning/orderUpRencontre",
      orderDownRencontre: "planning/orderDownRencontre",
      supprimerArbitre: "planning/supprimerArbitreRencontre",
      supprimerMarqueur: "planning/supprimerMarqueurRencontre",
      creerRencontre:"planning/creerRencontre",
      modifierRencontre: "planning/modifierRencontre",
      supprimerRencontre: "planning/supprimerRencontre",
      fetchPlannings: "planning/fetchPlannings",
    }),
    getDay(date) {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment(date).format('dddd');
    },
  },
}
</script>

<style lang="scss" scoped>

</style>