<script setup>

import SectionAdministration from "@/components/_common/administration/k-section-administration.vue";
import KComboModifiable from "@/components/_common/administration/k-combo-modifiable.vue";
</script>

<template>
  <v-row v-if="equipeCourante !== undefined">
    <section-administration>Informations générales
      <template #contenu>
        <k-text-field-modifiable
            @update-string="(nouveauNom) => envoyerRenommerEquipe(nouveauNom)"
            :editable="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')"
            titre="Nom de l'équipe"
            v-model="equipeCourante.nom"
            titre-action="Renommer"
        ></k-text-field-modifiable>
        <k-combo-modifiable
            @update-string="(nouvelleCategorie) => envoyerChangerCategorie(nouvelleCategorie)"
            :editable="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')"
            :loading="categorieLoading"
            :source-entry="equipeCourante.categorie"
            :items="categories"
            titre-action="Selectionner"
            titre="Catégorie"
        ></k-combo-modifiable>
        <k-combo-modifiable
            @update-string="(nouvelleLigue) => envoyerChangerLigue(nouvelleLigue)"
            :editable="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')"
            :loading="ligueLoading"
            :source-entry="equipeCourante.ligue"
            :items="ligues"
            titre-action="Selectionner"
            titre="Ligue"
        ></k-combo-modifiable>
        <k-combo-modifiable
            @update-string="(nouveauGenre) => envoyerChangerGenre(nouveauGenre)"
            :editable="isConnected && (user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN')"
            :loading="genreLoading"
            :source-entry="equipeCourante.genre"
            :items="genres"
            titre-action="Selectionner"
            titre="Genre"
        ></k-combo-modifiable>
      </template>
    </section-administration>
    <section-administration>Staff

    </section-administration>
    <section-administration>Patron de rencontre
    </section-administration>
    <section-administration>Joueurs
    </section-administration>
  </v-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";


export default {
  data() {
    return {
      categorieLoading: true,
      genreLoading: true,
      ligueLoading: true,
    }
  },
  props: {
    equipeId: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      getEquipeById: "equipe/getEquipeById",
      isConnected: "user/isConnected",
      user: "user/getUser",
      categories: "categorie/getCategorie",
      genres: "genre/getGenre",
      ligues: "ligue/getLigue",
    }),
    equipeCourante() {
      return this.getEquipeById(this.equipeId)
    },
  },
  methods: {
    ...mapActions({
      fetchEquipes: "equipe/fetchEquipes",
      renommerEquipe: "equipe/updateNomEquipe",
      fetchCategories: "categorie/fetchCategorie",
      changerCategorie: "equipe/updateCategorie",
      fetchGenres: "genre/fetchGenre",
      changerGenre: "equipe/updateGenre",
      fetchLigues: "ligue/fetchLigue",
      changerLigue: "equipe/updateLigue",
    }),
    envoyerRenommerEquipe(nouveauNomEquipe) {
      this.renommerEquipe({
        equipeId: this.equipeCourante.id,
        nouveauNomEquipe: nouveauNomEquipe
      })
    },
    envoyerChangerCategorie(nouvelleCategorie) {
      this.categorieLoading = true
      this.changerCategorie({
        equipeId: this.equipeCourante.id,
        nouvelleCategorie: nouvelleCategorie
      }).then(
          () => {this.categorieLoading = false}
      )
    },
    envoyerChangerLigue(nouvelleLigue) {
      this.ligueLoading = true
      this.changerLigue({
        equipeId: this.equipeCourante.id,
        nouvelleLigue: nouvelleLigue
      }).then(
          () => {this.ligueLoading = false}
      )
    },
    envoyerChangerGenre(nouveauGenre) {
      this.genreLoading = true
      this.changerGenre({
        equipeId: this.equipeCourante.id,
        nouveauGenre: nouveauGenre
      }).then(
          () => {this.genreLoading = false}
      )
    },
  },
  created() {
    this.fetchEquipes()
    this.fetchLigues().then(() => {this.ligueLoading = false})
    this.fetchCategories().then(() => {this.categorieLoading = false})
    this.fetchGenres().then(() => {this.genreLoading = false})

  }
}
</script>

<style scoped lang="scss">

</style>
