<template>
  <v-container v-if="patrons !== undefined && isConnected && user.role === 'ROLE_ADMIN' ">
    <div class="pa-0" v-if="patrons.length > 0">
      <v-card class="mb-2">
        <v-card-actions v-if="user.role === 'ROLE_ADMIN'">
          <dialog-creation-patron></dialog-creation-patron>
        </v-card-actions>
      </v-card>
      <v-card>
        <v-card-text
            class="hidden-sm-and-down"
        >
          <v-table
              height="80vh"
              fixed-header
              density="comfortable"
          >
            <thead>
            <tr>
              <th class="text-left">
                Equipe
              </th>
              <th class="text-left">
                Horaire
              </th>
              <th class="text-left">
                Jour
              </th>
              <th class="text-left">
                Officiels de marque
              </th>
              <th class="text-left">
                Arbitres
              </th>
              <th class="text-left">
                Actions
              </th>
            </tr>
            </thead>
            <tbody>
            <ligne-patron
                v-for="patron in patrons"
                :key="patron.id"
                :patron="patron"
                :nb-patron="patrons.length"
            ></ligne-patron>
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
      <div class="hidden-md-and-up" >
        <carte-patron
            v-for="patron in patrons"
            :key="patron.id"
            :patron="patron"
            :nb-patron="patrons.length"
        ></carte-patron>
      </div>
    </div>
    <no-content-card v-else :enable-actions="isConnected && user.isPatronEnabled">
      Oups ^^ : il n'y a aucun patrons !
      <template #actions>
        <dialog-creation-patron></dialog-creation-patron>
      </template>
    </no-content-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import DialogCreationPatron from "@/components/ressources/patron/dialog-creation-patron.vue";
import CartePatron from "@/components/ressources/patron/representations/carte-patron.vue";
import LignePatron from "@/components/ressources/patron/representations/ligne-patron.vue";
import NoContentCard from "@/components/_common/noContentCard.vue";
export default {
  name: "liste-patrons",
    components: {
      NoContentCard,
      LignePatron,
      CartePatron,
      DialogCreationPatron,
    },

  computed: {
    ...mapGetters ({
      patrons: "patron/getPatrons",
      isConnected: "user/isConnected",
      user: "user/getUser",

    })
  },
  methods: {
    ...mapActions({
      fetchPatrons: "patron/fetchPatrons",
    }),

  },
  created() {
    this.fetchPatrons()
  }
}
</script>