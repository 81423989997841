<template>
  <v-card variant="outlined">
    <v-card-title>Semaine n°{{ planning.semaine}} de {{planning.anne}}</v-card-title>
    <v-card-subtitle>du {{ getDateFriday }} au {{ getDateSunday }}</v-card-subtitle>
    <v-card-text>Nombre de rencontres : <v-chip color="primary">{{ planning.rencontres.length }}</v-chip></v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn class="ma-2" variant="elevated" color="option" :to="'/planning/' + planning.semaine + '/' +planning.anne" prepend-icon="mdi-eye">Consulter</v-btn>
      <delete-dialog v-if="user.role === 'ROLE_REFERENT' || user.role === 'ROLE_ADMIN'" @VALIDATE="deletePlanning(planning)"></delete-dialog>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment"
import DeleteDialog from "@/components/_common/dialogs/delete-dialog.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "carte-planning",
  components: {DeleteDialog},
  props: {
    planning: {
      type: Object
    },
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    getDateFriday() {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment().day("vendredi").year(this.planning.anne).week(this.planning.semaine).format('L');
    },
    getDateSunday() {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment().day("dimanche").year(this.planning.anne).week(this.planning.semaine).format('L');
    }
  },
  methods: {
    ...mapActions({
      deletePlanning: "planning/deletePlanning"
    })
  },
}
</script>