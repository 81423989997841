<template>
  <v-card width="100%">
    <v-form
        ref="form"
        @submit.prevent="">
      <v-card-title>
        Modifier de patron
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <v-label>
              Equipe
            </v-label>
            <v-text-field
                @input="v$.patronTemp.equipe.$touch()"
                @blur="v$.patronTemp.equipe.$touch()"
                v-model.trim="patronTemp.equipe"
                :counter="v$.patronTemp.equipe.maxLength.$params.max"
                label="equipe"
                :error-messages="equipeErrors"
                required
            ></v-text-field>
            <v-text-field
                @input="v$.patronTemp.coach.$touch()"
                @blur="v$.patronTemp.coach.$touch()"
                v-model.trim="patronTemp.coach"
                :counter="v$.patronTemp.coach.maxLength.$params.max"
                label="coach"
                :error-messages="coachErrors"
                required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-label>
              Horaire / jour
            </v-label>
            <v-select
                label="Select"
                :items="['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']"
                v-model="patronTemp.jourSemaine"
                :error-messages="jourSemaineErrors"
            ></v-select>
            <v-text-field
                type="time"
                v-model="patronTemp.horaire"
                label="horaire"
                required
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-label>
              Officiels
            </v-label>
            <v-switch
                color="option"
                v-model="patronTemp.sontDesignesArbitres"
                label="Sont désignés arbitres"
            ></v-switch>
            <v-switch
                color="option"
                v-model="patronTemp.sontDesignesMarqueurs"
                label="Sont désignés OTM"
            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      <common-actions
          action-color="success"
          @VALIDATE="submit({
            id: patronTemp.id,
            equipe: patronTemp.equipe,
            coach: patronTemp.coach,
            jourSemaine: patronTemp.jourSemaine,
            horaire: patronTemp.horaire,
            sontDesignesArbitres: patronTemp.sontDesignesArbitres,
            sontDesignesMarqueurs: patronTemp.sontDesignesMarqueurs
          })"
          @INVALIDATE="$emit('INVALIDATE');"
      >Modifier</common-actions>
    </v-form>
  </v-card>
</template>

<script>


import {useVuelidate} from "@vuelidate/core";
import {maxLength, required} from "@vuelidate/validators";
import structuredClone from '@ungap/structured-clone';
import CommonActions from "@/components/_common/common-actions.vue";
let equipeLength = 32
let coachLength = 128

export default {
  name: "edit-patron-card",
  components: {CommonActions},
  data() {
    return {
      patronTemp: {}
    }
  },
  props: {
    patron: {
      type: Object,
      required: true
    },
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  created() {
    this.patronTemp = structuredClone(this.patron)
  },
  validations() {
    return {
      patronTemp: {
        equipe: {
          required,
          maxLength: maxLength(equipeLength)
        },
        coach: {
          required,
          maxLength: maxLength(coachLength)
        },
      }
    };
  },
  methods: {
    submit(patron) {
      this.v$.patronTemp.$touch()
      if (!this.v$.$invalid) {
        this.$emit('UPDATE', patron)
        this.reset()
        this.dialog = false
      }
    },
    reset() {
      this.$refs.form.reset()
    }
  },

  computed: {
    equipeErrors() {
      const errors = []
      if (!this.v$.patronTemp.equipe.$dirty) return errors
      this.v$.patronTemp.equipe.maxLength.$invalid && errors.push("Le nom d'equipe ne doit pas dépasser "+ equipeLength +" caractéres")
      this.v$.patronTemp.equipe.required.$invalid && errors.push("nom d'equipe requis.")
      return errors
    },
    coachErrors() {
      const errors = []
      if (!this.v$.patronTemp.coach.$dirty) return errors
      this.v$.patronTemp.coach.maxLength.$invalid && errors.push("Le nom du coach ne doit pas dépasser "+ coachLength +" caractéres")
      this.v$.patronTemp.coach.required.$invalid && errors.push("nom de coach requis.")
      return errors
    },
  },
}
</script>