<template>
  <dialog-creation>
    Créer un patron
    <template v-slot:form="{ onClose }">
      <v-form
          ref="form"
          @submit.prevent=""
      >
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-label>
                équipe
              </v-label>
              <v-text-field
                  @input="v$.patronTemp.equipe.$touch()"
                  @blur="v$.patronTemp.equipe.$touch()"
                  v-model.trim="patronTemp.equipe"
                  :counter="v$.patronTemp.equipe.maxLength.$params.max"
                  label="equipe"
                  :error-messages="equipeErrors"
                  required
              ></v-text-field>
              <v-text-field
                  @input="v$.patronTemp.coach.$touch()"
                  @blur="v$.patronTemp.coach.$touch()"
                  v-model.trim="patronTemp.coach"
                  :counter="v$.patronTemp.coach.maxLength.$params.max"
                  label="coach"
                  :error-messages="coachErrors"
                  required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-label>
                Horaire / jour
              </v-label>
              <v-select
                  label="Select"
                  :items="['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE']"
                  v-model="patronTemp.jourSemaine"
              ></v-select>
              <v-text-field
                  type="time"
                  v-model="patronTemp.horaire"
                  label="horaire"
                  required
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="4">
              <v-label>
                Arbitres
              </v-label>
              <v-switch
                  color="option"
                  v-model="patronTemp.sontDesignesArbitres"
                  label="Sont désignés arbitres"
              ></v-switch>
              <v-switch
                  color="option"
                  v-model="patronTemp.sontDesignesMarqueurs"
                  label="Sont désignés marqueurs"
              ></v-switch>
            </v-col>
          </v-row>
        <common-actions
            action-color="success"
            @VALIDATE="() => {
              submit({
                id: patronTemp.id,
                equipe: patronTemp.equipe,
                coach: patronTemp.coach,
                jourSemaine: patronTemp.jourSemaine,
                horaire: patronTemp.horaire,
                sontDesignesArbitres: patronTemp.sontDesignesArbitres,
                sontDesignesMarqueurs: patronTemp.sontDesignesMarqueurs
              })
              onClose
            }"
            @INVALIDATE="onClose"
        >Confirmer</common-actions>
      </v-form>
    </template>
  </dialog-creation>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import {maxLength, required} from "@vuelidate/validators";
import {mapActions} from "vuex";
import CommonActions from "@/components/_common/common-actions.vue";
import DialogCreation from "@/components/_common/dialogs/dialog-creation.vue";

let equipeLength = 32
let coachLength = 128
export default {
  name: "dialog-creation-patron",
  components: {DialogCreation, CommonActions},
  data () {
    return {
      dialog: false,
      patronTemp: {
        sontDesignesArbitres: false,
        sontDesignesMarqueurs: false
      }
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      patronTemp: {
        equipe: {
          required,
          maxLength: maxLength(equipeLength)
        },
        coach: {
          required,
          maxLength: maxLength(coachLength)
        },
      }
    };
  },
  methods: {
    ...mapActions({
      creerPatron: "patron/creerPatron"
    }),
    submit(data) {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.creerPatron(data)
        this.reset()
      }
    },
    reset() {
      this.$refs.form.reset()
      this.v$.patronTemp.$reset()
    }
  },
  computed: {
    equipeErrors() {
      const errors = []
      if (!this.v$.patronTemp.equipe.$dirty) return errors
      this.v$.patronTemp.equipe.maxLength.$invalid && errors.push("Le nom d'equipe ne doit pas dépasser "+ equipeLength +" caractéres")
      this.v$.patronTemp.equipe.required.$invalid && errors.push("nom d'equipe requis.")
      return errors
    },
    coachErrors() {
      const errors = []
      if (!this.v$.patronTemp.coach.$dirty) return errors
      this.v$.patronTemp.coach.maxLength.$invalid && errors.push("Le nom du coach ne doit pas dépasser "+ coachLength +" caractéres")
      this.v$.patronTemp.coach.required.$invalid && errors.push("nom de coach requis.")
      return errors
    },
  },
}
</script>

<style >
@media only screen and (min-width: 1256px) {
  .v-dialog {
    width: 65%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1255px) {
  .v-dialog {
    width: 75%;
  }
}
@media only screen and (max-width: 767px) {
  .v-dialog {
    width: 100%;
  }
}
</style>