<template>
  <side-panel-view-base :title="Equipes">
    <template #titre-liste>
      <h2 class="text-center py-4">Nos équipes</h2>
      <v-divider></v-divider>
    </template>
    <template v-if="loaded" #list-items>
      <list-item-equipe v-for="equipe in equipes" :key="equipe.id" :equipe="equipe"></list-item-equipe>
    </template>
    <template v-if="loaded" #titre-carte>
      <div v-if="loaded">{{ nomEquipeCourante }}</div></template>
    <template v-if="loaded" #contenu-carte>
      <router-view v-if="loaded" :equipe="nomEquipeCourante"></router-view>
    </template>
  </side-panel-view-base>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SidePanelViewBase from "@/components/_common/side-panel-view-base.vue";
import ListItemEquipe from "@/components/ressources/equipe/representations/list-item-equipe.vue";

export default {
  name: "equipe-view",
  components: { ListItemEquipe, SidePanelViewBase},
  data() {
    return {
      loaded: false
    }
  },
  props: {
    equipeId: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      equipes: "equipe/getEquipes",
      getEquipeById: "equipe/getEquipeById",
    }),
    nomEquipeCourante() {
      return this.getEquipeById(this.equipeId).nom
    },
  },
  methods: {
    ...mapActions({
      fetchEquipes: "equipe/fetchEquipes"
    })
  },
  created() {
    this.fetchEquipes().then(() => this.loaded = true)
  }
}
</script>
