import axios from "axios";
const apiPath = "ligue"
export default {
    namespaced: true,
    state: {
        ligue: []
    },
    mutations: {
        SET_LIGUE(state, _ligues) {
            state.ligue = _ligues;
        },
    },
    actions: {
        fetchLigue({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_URL_BACK}/`+ apiPath)
                .then((response) => {
                    commit('SET_LIGUE', response.data)
                })
        },
    },
    getters: {
        getLigue(state) {
            return state.ligue;
        },

    },
}