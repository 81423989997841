<template>
  <v-btn
      color="success"
      variant="outlined"
  >
    <slot></slot>
    <v-dialog
        v-model="dialog"
        activator="parent"
    >
      <v-card>
        <v-card-title><slot></slot></v-card-title>
        <v-card-text>
          <slot name="form"></slot>
        </v-card-text>
        <v-card-actions class="justify-end">
          <slot name="actions" :onClose="onClose"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
export default {
  name: "dialog-creation",
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    onClose() {
      this.dialog = false
    }
  },
}
</script>
