<template>
  <v-container>
    <v-card class="w-100 h-100">
      <my-button>Bonjour</my-button>
      <k-chip>Bonjour</k-chip>
    </v-card>
  </v-container>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>

</style>
