<template>
  <dialog-creation>
    Créer une équipe
    <template #form>
      <v-form
          ref="form"
          @submit.prevent=""
      >
        <v-text-field
            @input="v$.equipeToCreate.nomEquipe.$touch()"
            @blur="v$.equipeToCreate.nomEquipe.$touch()"
            v-model.trim="equipeToCreate.nomEquipe"
            :counter="v$.equipeToCreate.nomEquipe.maxLength.$params.max"
            label="nom d'equipe"
            :error-messages="nomEquipeErrors"
            required
        ></v-text-field>
        <v-combobox
            :disabled="categorieLoading"
            :loading="categorieLoading"
            label="catégorie"
            v-model="equipeToCreate.categorie"
            :items="categories"
        ></v-combobox>
        <v-combobox
            :disabled="liguesLoading"
            :loading="liguesLoading"
            label="ligue"
            v-model="equipeToCreate.ligue"
            :items="ligues"
        ></v-combobox>
        <v-combobox
            :disabled="genreLoading"
            :loading="genreLoading"
            label="genre"
            v-model="equipeToCreate.genre"
            :items="genres"
        ></v-combobox>
      </v-form>
    </template>
    <template #actions="{ onClose }">
      <common-actions
          action-color="success"
          @VALIDATE="() => {
              onClose()
              submit()
            }"
          @INVALIDATE="onClose"
      >Confirmer</common-actions>
    </template>
  </dialog-creation>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import {maxLength, required} from "@vuelidate/validators";
import {mapActions, mapGetters} from "vuex";
import CommonActions from "@/components/_common/common-actions.vue";
import DialogCreation from "@/components/_common/dialogs/dialog-creation.vue";

let equipeLength = 32
export default {
  name: "dialog-creation-equipe",
  components: {DialogCreation, CommonActions},
  data () {
    return {
      dialog: false,
      equipeToCreate: {
        nomEquipe: "",
        categorie: "",
        ligue: "",
        genre: ""
      },
      categorieLoading: true,
      genreLoading: true,
      liguesLoading: true,
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      equipeToCreate: {
        nomEquipe: {
          required,
          maxLength: maxLength(equipeLength)
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      ligues: "ligue/getLigue",
      genres: "genre/getGenre",
      categories: "categorie/getCategorie",
    }),
    nomEquipeErrors() {
      const errors = []
      if (!this.v$.equipeToCreate.nomEquipe.$dirty) return errors
      this.v$.equipeToCreate.nomEquipe.maxLength.$invalid && errors.push("Le nom d'equipe ne doit pas dépasser "+ equipeLength +" caractéres")
      this.v$.equipeToCreate.nomEquipe.required.$invalid && errors.push("nom d'equipe requis.")
      return errors
    },
  },
  methods: {
    ...mapActions({
      creerEquipe: "equipe/creerEquipe",
      fetchCategorie: "categorie/fetchCategorie",
      fetchLigue: "ligue/fetchLigue",
      fetchGenre: "genre/fetchGenre",
    }),
    submit() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.creerEquipe(this.equipeToCreate)
        this.reset()
      }
    },
    reset() {
      this.$refs.form.reset()
      this.v$.$reset()
    }
  },
  created() {
    this.fetchLigue().then(
      this.liguesLoading = false
    )
    this.fetchGenre().then(
      this.genreLoading = false
    )
    this.fetchCategorie().then(
      this.categorieLoading = false
    )
  }
}
</script>