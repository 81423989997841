<template>
  <v-card>
    <v-form ref="form" @submit.prevent="">
    <v-card-title>S'ajouter à la liste des {{ raison }}</v-card-title>
    <v-card-text>
      <v-text-field
          @input="v$.data.prenom.$touch()"
          @blur="v$.data.prenom.$touch()"
          v-model.trim="data.prenom"
          :error-messages="prenomErrors"
          label="prenom"
          required
      ></v-text-field>
      <v-text-field
            @input="v$.data.nom.$touch()"
            @blur="v$.data.nom.$touch()"
            v-model.trim="data.nom"
            label="nom"
            :error-messages="nomErrors"
            required
        ></v-text-field>
    </v-card-text>
    <common-actions
        action-color="success"
        @VALIDATE="submit(data.nom, data.prenom)"
        @INVALIDATE="reset();$emit('CANCEL')"
    >Confirmer</common-actions>
    </v-form>
  </v-card>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import CommonActions from "@/components/_common/common-actions.vue";

export default {
  components: {CommonActions},
  props: {
    raison: {
      type: String,
    },
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  data () {
    return {
      data:{
        nom: "",
        prenom: ""
      }
    }
  },
  validations() {
    return {
      data: {
        nom: {
          required,
        },
        prenom: {
          required,
        }
      }
    }
  },
  methods: {
    submit(nom, prenom) {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        console.log(nom, prenom)
        this.$emit('AJOUTER_OFFICIEL', {nom: nom, prenom:prenom})
        this.reset()
      }
    },
    reset() {
      this.$refs.form.reset()
      this.v$.data.nom.$reset() // L'un puis l'aautre de maniére précise pour ne pas reset les autres
      this.v$.data.prenom.$reset()// form dans le cas de nesting
    }
  },
  computed: {
    nomErrors() {
      const errors = []
      if (!this.v$.data.nom.$dirty) return errors
      this.v$.data.nom.required.$invalid && errors.push('nom requis.')
      return errors
    },
    prenomErrors() {
      const errors = []
      if (!this.v$.data.prenom.$dirty) return errors
      this.v$.data.prenom.required.$invalid && errors.push('prenom requis.')
      return errors
    },
  },

}
</script>