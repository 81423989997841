<template>
  <v-btn
    color="success"
    @click="updateRencontre"
    icon
  >
    <v-icon>mdi-pencil</v-icon>
    <v-dialog
        v-model="dialog"
        activator="parent"
    >
      <v-card >
        <v-form ref="form" @submit.prevent="">
          <v-card-title>
            Modifier de rencontre
          </v-card-title>
          <v-card-text v-if="rencontre !== undefined">
            <v-row>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-label>
                  équipes
                </v-label>
                <v-text-field
                    @input="v$.rencontre.equipe.$touch()"
                    @blur="v$.rencontre.equipe.$touch()"
                    v-model.trim="rencontre.equipe"
                    label="equipe"
                    :error-messages="equipeErrors"
                    required
                ></v-text-field>
                <v-text-field
                    @input="v$.rencontre.coach.$touch()"
                    @blur="v$.rencontre.coach.$touch()"
                    v-model.trim="rencontre.coach"
                    label="coach"
                    :error-messages="coachErrors"
                    required
                ></v-text-field>
                <v-text-field
                    @input="v$.rencontre.adversaire.$touch()"
                    @blur="v$.rencontre.adversaire.$touch()"
                    v-model.trim="rencontre.adversaire"
                    :error-messages="adversaireErrors"
                    label="adversaire"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-label>Lieu</v-label>
                <v-switch
                    color="option"
                    v-model="rencontre.estDomicile"
                    :label="rencontre.estDomicile ? 'à domicile' : 'en extérieur'"
                ></v-switch>
                <v-text-field
                    v-if="!rencontre.estDomicile"
                    @input="v$.rencontre.adresse.$touch()"
                    @blur="v$.rencontre.adresse.$touch()"
                    v-model.trim="rencontre.adresse"
                    :error-messages="adresseErrors"
                    label="adresse"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-label>Date / Horaire</v-label>
                <v-text-field
                    type="time"
                    v-model="rencontre.horaireRencontre"
                    label="Horaire"
                    required
                ></v-text-field>
                <v-text-field
                    type="date"
                    v-model="rencontre.dateRencontre"
                    label="date"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="3" lg="3">
                <v-label>
                  Arbitres
                </v-label>
                <v-switch
                    color="option"
                    v-model="rencontre.sontDesignesArbitres"
                    :label="rencontre.sontDesignesArbitres ? 'Désignés' : 'Non désignés'"
                ></v-switch>
                <v-label>
                  Marqueurs
                </v-label>
                <v-switch
                    color="option"
                    v-model="rencontre.sontDesignesMarqueurs"
                    :label="rencontre.sontDesignesMarqueurs ? 'Désignés' : 'Non désignés'"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <common-actions
              @VALIDATE="dialog=false;$emit('VALIDATE', rencontre);reset()"
              @INVALIDATE="dialog=false;reset()"
          >Modifier</common-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import { maxLength} from "@vuelidate/validators";
import CommonActions from "@/components/_common/common-actions.vue";

let adresseLength = 32
let equipeLength = 32
let coachLength = 128
let adversaireLength = 32
export default {
  name: "dialog-edition-rencontre",
  components: {CommonActions},
  props: {
    rencontreBis: {
      type: Object
    },
  },
  data () {
    return {
      dialog: false,
      rencontre: undefined,
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      rencontre: {
        adresse: {
          maxLength: maxLength(adresseLength)
        },
        adversaire: {
          maxLength: maxLength(adversaireLength)
        },
        equipe: {
          maxLength: maxLength(equipeLength)
        },
        coach: {
          maxLength: maxLength(coachLength)
        },
      }
    };
  },
  methods: {
    reset() {
      this.$refs.form.reset()
    },
    updateRencontre() {
      this.rencontre = JSON.parse(JSON.stringify(this.rencontreBis))
    }
  },
}
</script>