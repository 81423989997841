<template>
  <v-btn
      color="option"
      variant="elevated"
      :width="width"
  >
    Se connecter
    <v-dialog
        v-model="dialog"
        activator="parent"
        width="auto"
    >
      <v-card>
        <v-form ref="form" @submit.prevent="">
          <v-card-title>
            Connexion pour admin ou référent
          </v-card-title>
          <v-card-text>
            <v-text-field
                @input="v$.username.$touch()"
                @blur="v$.username.$touch()"
                v-model.trim="username"
                :counter="v$.username.maxLength.$params.max"
                label="username"
                :error-messages="usernameErrors"
                required
            ></v-text-field>
            <v-text-field
                type="password"
                @input="v$.password.$touch()"
                @blur="v$.password.$touch()"
                v-model.trim="password"
                :counter="v$.password.maxLength.$params.max"
                :error-messages="passwordErrors"
                label="Mot de passe"
                required
            ></v-text-field>
          </v-card-text>
          <common-actions
              action-color="option"
              @VALIDATE="dialog=false;submit({username:username, password:password})"
              @INVALIDATE="reset();dialog=false;"
          >Se connecter</common-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>

import { useVuelidate } from "@vuelidate/core"
import {maxLength, required} from "@vuelidate/validators";
import {mapActions} from "vuex";
import CommonActions from "@/components/_common/common-actions.vue";

let usernameLength = 256
let passwordLength = 256

export default {
  name: "boutton-connexion",
  components: {CommonActions},
  props: {
    width: {
      type: String,
      default: ""
    },
  },
  data () {
    return {
      dialog: false,
      username: "",
      password: ""
    }
  },
  setup () {
    return {
      v$: useVuelidate()
    };
  },
  validations() {
    return {
      username: {
        required,
        maxLength: maxLength(usernameLength)
      },
      password: {
        required,
        maxLength: maxLength(passwordLength)
      }
    };
  },
  methods: {
    ...mapActions({
      tryConnection: "user/tryConnectionUsers"
    }),
    submit({username, password}) {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        this.tryConnection({username, password})
        this.reset()
        this.dialog = false
      }
    },
    reset() {
      this.$refs.form.reset()
      this.v$.username.$reset() // L'un puis l'aautre de maniére précise pour ne pas reset les autres
      this.v$.password.$reset()// form dans le cas de nesting
    }
  },

  computed: {
    usernameErrors() {
      const errors = []
      if (!this.v$.username.$dirty) return errors
      this.v$.username.maxLength.$invalid && errors.push('Le username ne doit pas dépasser '+ usernameLength +' caractéres')
      this.v$.username.required.$invalid && errors.push('username requis.')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.v$.password.$dirty) return errors
      this.v$.password.maxLength.$invalid && errors.push('Le password ne doit pas dépasser '+ passwordLength +' caractéres')
      this.v$.password.required.$invalid && errors.push('Mot de pas requis obligatoire.')
      return errors
    },
  },
}
</script>