<script setup>

</script>

<template>
  <v-col class="mb-4" cols="6" sm="12" md="6" lg="6">
    <span class="text-h6" style="color: black">
      <slot></slot>
    </span>
    <v-divider class="pb-2" />
    <slot name="contenu"></slot>
  </v-col>
</template>

<style scoped lang="scss">

</style>