<template>
  <s-info-modifiable
    :titre="titre"
    :valeur="entryToModify"
    :titre-action="titreAction"
    :editable="editable"
    :loading="loading"
    @validateUpdate="sendUpdate"
    @cancelUpdate="reset"
  >
    <template #champ>
      <v-combobox
        v-model="entryToModify"
        :items="items"
        hide-no-data
        :label="titre"
        :required="true"
      />
    </template>
  </s-info-modifiable>
</template>

<script>
import SInfoModifiable from "@/components/_common/administration/k-info-modifiable.vue";


export default {
  name: 'k-combo-modifiable',
  components: {SInfoModifiable},
  props: {
    titre: {
      type: String,
      required: true,
    },
    titreAction: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    sourceEntry: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entryToModify: undefined,
    }
  },
  created() {
    this.entryToModify = this.sourceEntry
  },
  watch: {
    sourceEntry: {
      handler() {
        this.entryToModify = this.sourceEntry
      },
      deep: true,
    },
  },
  methods: {
    sendUpdate() {
      this.$emit("update-string", this.entryToModify)
    },
    reset() {
      this.entryToModify = this.sourceEntry
    },
  },
}
</script>

<style scoped></style>
