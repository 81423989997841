<template>
  <v-table
      density="comfortable"
      class="pa-0"
      fixed-header=""
      height="70vh"
  >
    <thead
    >
    <tr>
      <th>Equipe</th>
      <th>Adversaire</th>
      <th>Lieu</th>
      <th>Date / Horaire</th>
      <th>Arbitres</th>
      <th>Table de marque</th>
      <th v-if="isAdmin">Modifier</th>
    </tr>
    </thead>
    <tbody>
    <tr
        v-for="rencontre in planning.rencontres" :key="rencontre.id"
    >
      <td>
        <p>{{ rencontre.equipe }}</p>
        <p>{{ rencontre.coach }}</p>
      </td>
      <td>{{ rencontre.adversaire }}</td>
      <td>
        <div v-if="rencontre.estDomicile">
          <v-chip color="domicile" >A domicile</v-chip>
        </div>
        <div v-else>
          <v-chip color="exterieur" >A l'extérieur</v-chip>
          <p>{{ rencontre.adresse }}</p>
        </div>
      </td>
      <td>
        <p v-if="rencontre.dateRencontre !== null">{{ getDay(rencontre.dateRencontre) + ", " + (new Date(rencontre.dateRencontre.toLocaleString())).toLocaleDateString()}}</p>
        <p v-if="rencontre.horaireRencontre !== null">{{ "à "+ rencontre.horaireRencontre }}</p>
      </td>
      <td>
        <div v-if="rencontre.sontDesignesArbitres && rencontre.estDomicile">
          <v-chip
              class="ma-2 pa-3"
              color="designes"
          >Désignés</v-chip>
        </div>
        <chip-container
            :canEdit="isUser"
            raison="arbitre"
            v-else-if="!rencontre.sontDesignesArbitres && rencontre.estDomicile"
            :items="rencontre.arbitres"
            @AJOUTER_OFFICIEL="(data) => ajouterArbitre({planning, rencontre, data})"
            @SUPPRIMER_OFFICIEL="(id) => supprimerArbitre({planning, rencontre, id})"
        ></chip-container>
      </td>
      <td>
        <div v-if="rencontre.sontDesignesMarqueurs && rencontre.estDomicile">
          <v-chip
              class="ma-2 pa-3"
              color="designes"
          >Désignés</v-chip>
        </div>
        <chip-container
            :canEdit="isUser"
            raison="OTM"
            v-else-if="!rencontre.sontDesignesMarqueurs && rencontre.estDomicile"
            :items="rencontre.marqueurs"
            @AJOUTER_OFFICIEL="(data) => ajouterMarqueur({planning, rencontre, data})"
            @SUPPRIMER_OFFICIEL="(id) => supprimerMarqueur({planning, rencontre, id})"
        ></chip-container>
      </td>
      <td v-if="isAdmin">
        <v-row class="pa-4">
          <v-col class="pa-1">
            <v-btn
                :disabled="rencontre.ordre < 1"
                color="option"
                variant="outlined"
                icon
                @click="orderUpRencontre({planningId: planning.id, rencontreId:rencontre.id})"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
          </v-col>
          <v-col class="pa-1">
            <v-btn
                :disabled="rencontre.ordre >= planning.rencontres.length-1"
                color="option"
                variant="outlined"
                icon
                @click="orderDownRencontre({planningId: planning.id, rencontreId:rencontre.id})"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
          <v-col class="pa-1">
            <dialog-edition-rencontre
                @VALIDATE="(r) => modifierRencontre({rencontre:r, _planning:planning})"
                :rencontre-bis="rencontre"
            ></dialog-edition-rencontre>
          </v-col>
          <v-col class="pa-1">
            <delete-dialog
                @VALIDATE="() => {supprimerRencontre({_idRencontre:rencontre.id, _idPlanning:planning.id})}"
            ></delete-dialog>
          </v-col>
        </v-row>
      </td>
    </tr>
    </tbody>
  </v-table>
</template>

<script>
import DeleteDialog from "@/components/_common/dialogs/delete-dialog.vue";
import {mapActions, mapGetters} from "vuex";
import moment from "moment/moment";
import ChipContainer from "@/components/ressources/officiel/chip-container.vue";
import DialogEditionRencontre from "@/components/ressources/rencontre/dialog-edition-rencontre.vue";

export default {
  components: {DialogEditionRencontre, ChipContainer, DeleteDialog},
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    },
    isUser: {
      type: Boolean,
      default: false
    },
    planning: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      common: "common/getCommons"
    }),
  },
  methods: {
    ...mapActions({
      ajouterArbitre: "planning/ajouterArbitreRencontre",
      ajouterMarqueur: "planning/ajouterMarqueurRencontre",
      orderUpRencontre: "planning/orderUpRencontre",
      orderDownRencontre: "planning/orderDownRencontre",
      supprimerArbitre: "planning/supprimerArbitreRencontre",
      supprimerMarqueur: "planning/supprimerMarqueurRencontre",
      creerRencontre:"planning/creerRencontre",
      modifierRencontre: "planning/modifierRencontre",
      supprimerRencontre: "planning/supprimerRencontre",
      fetchPlannings: "planning/fetchPlannings",
    }),
    getDay(date) {
      moment.updateLocale('fr', {
        week: {
          dow : 1, // Monday is the first day of the week.
        }
      });
      return moment(date).format('dddd');
    },
  },
}
</script>

<style lang="scss" scoped>
td {
  padding: 6px 0px !important;
}
</style>