import axios from "axios";
const apiPath = "equipe"
export default {
    namespaced: true,
    state: {
        equipe: []
    },
    mutations: {
        SET_EQUIPE(state, _equipe) {
            state.equipe = _equipe;
        },
        ADD_EQUIPE(state, _equipe) {
            const indexEquipe = state.equipe.findIndex((equipe) => equipe.id === _equipe.id)
            if(indexEquipe < 0) {
                state.equipe.push(_equipe)
            }
        },
        PUT_EQUIPE(state, _equipe) {
            const indexToUpdate = state.equipe.findIndex((equipe) => equipe.id === _equipe.id)
            if (indexToUpdate >= 0) {
                state.equipe.splice(indexToUpdate, 1, _equipe)
            }
        },
        DELETE_EQUIPE(state, _equipe) {
            const indexToUpdate = state.equipe.findIndex((equipe) => equipe.id === _equipe.id)
            if (indexToUpdate >= 0) {
                state.equipe.splice(indexToUpdate, 1)
            }
        }
    },
    actions: {
        fetchEquipes({ commit }) {
            return axios
                .get(`${process.env.VUE_APP_URL_BACK}/`+ apiPath)
                .then((response) => {
                    commit('SET_EQUIPE', response.data)
                })
        },
        creerEquipe({commit}, data) {
            return axios
              .post(`${process.env.VUE_APP_URL_BACK}/`+ apiPath, data)
              .then((response) => {
                  commit('ADD_EQUIPE', response.data)
              })
        },
        async updateNomEquipe({commit}, data) {
            await axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${data.equipeId}/nom`, data.nouveauNomEquipe)
              .then((response) => {
                  commit('PUT_EQUIPE', response.data)
              })
        },
        async updateCategorie({commit}, data) {
            await axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${data.equipeId}/categorie`, data.nouvelleCategorie)
              .then((response) => {
                  commit('PUT_EQUIPE', response.data)
              })
        },
        async updateLigue({commit}, data) {
            await axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${data.equipeId}/ligue`, data.nouvelleLigue)
              .then((response) => {
                  commit('PUT_EQUIPE', response.data)
              })
        },
        async updateGenre({commit}, data) {
            await axios
              .put(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${data.equipeId}/genre`, data.nouveauGenre)
              .then((response) => {
                  commit('PUT_EQUIPE', response.data)
              })
        },
        deleteEquipe({commit}, equipe) {
            return axios
                .delete(`${process.env.VUE_APP_URL_BACK}/`+ apiPath + `/${equipe.id}`)
                .then(commit('DELETE_EQUIPE', equipe))
        },
    },
    getters: {
        getEquipes(state) {
            return state.equipe;
        },
            getEquipeById: (state) => (_equipeId) => {
            return state.equipe.find((equipe) => equipe.id === _equipeId)
        },

    },
}